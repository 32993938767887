import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const header = (
    <h1
      style={{
        ...scale(location.pathname === rootPath ? 1.0 : 0.5),
        marginBottom: rhythm(1.5),
        marginTop: 0,
      }}
    >
      <Link
        style={{
          boxShadow: `none`,
          textDecoration: `none`,
          lineHeight: 1,
          color: `inherit`,
        }}
        to={`/`}
      >
        <div>如<span className="sakura">鯨</span></div>
        <div>向<span className="ocean">海</span></div>
      </Link>
    </h1>
  )
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: `720px`,
        padding: `${rhythm(1.5)} ${rhythm(1 / 4)}`,
      }}
    >
      <header>{header}</header>
      <main>{children}</main>
      <footer>
        &copy; {new Date().getFullYear()} Crafted with <span className="sakura">❤︎</span> by <span className="ocean">Polaris</span>
      </footer>
    </div>
  )
}

export default Layout
