import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

Wordpress2016.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
    "*": {
      fontFamily: `"Hiragino Mincho Pro", "STSong", "Vollkorn", serif`,
      color: `#4E4E4E`,
    },
    "body": {
      backgroundColor: `#FFFEFA`,
    },
    ".card *": {
      color: `#FFF`,
    },
    ".sakura": {
      color: `#E16B8C`,
    },
    ".ocean": {
      color: `#054286`,
    },
  }
}

delete Wordpress2016.googleFonts

const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
